import axios from "axios";
import { useState, useEffect } from "react";
import { usePageName } from "react-page-name";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import "../contact/contact.scss"


const Contact = () => {

    const location = useLocation();

    const postSlug = location.pathname.split("/")[2];

    const [post, setPost] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [mailSend, setMailSend] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    useEffect(() => {
        const fetchAllPost = async () => {
            try {
                const res = await axios.get(`https://api.augschoell.com/wp-json/wp/v2/kompositionen?slug=${postSlug}`)
                setPost(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPost()
    }, [postSlug])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendEmail = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://augschoell.com/sendMail.php', {
                name: formData.name,
                email: formData.email,
                message: formData.message,
                anfrage: post.title.rendered,
            });

            console.log(response.data);

            setMailSend(true)

        } catch (err) {
            console.error('Error sending email:', err);
        }
    };

    usePageName(isLoading ? "Laden | Jakob Augschöll" : `Anfragen - ${post.title.rendered} | Jakob Augschöll`);

    return (
        <div className="contact">
            {isLoading ? <div className="loading" /> : <>
                <div className="container">
                    <motion.div
                        className="header"
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                    >
                        <Link to={`/post/${post.slug}`}>
                            <ArrowBackOutlinedIcon />
                        </Link>
                        <h1>Anfrage: {post.title.rendered}</h1>
                    </motion.div>
                    <motion.form
                        className="inputsContact"
                        method="post"
                        onSubmit={sendEmail}
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                    >
                        <input
                            name="name"
                            placeholder="Name und Nachname"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <input
                            name="email"
                            type="email"
                            placeholder="E-Mail"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <textarea
                            name="message"
                            cols="30"
                            rows="10"
                            placeholder="Schreibe deine Nachricht (optional)..."
                            value={formData.message}
                            onChange={handleChange}
                        />
                        <div className="senden">
                            <button type="submit" disabled={mailSend}>Absenden</button>
                            {mailSend &&
                                <div className="gesendet">
                                    <span>Anfrage erfolgreich<br />gesendet!</span>
                                </div>
                            }
                        </div>
                    </motion.form>
                </div>
            </>}
        </div>
    )
};

export default Contact