import React from "react";
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Posts from "../../components/posts/posts";
import "./home.scss"

const Home = () => {

    usePageName(`Jakob Augschöll`);

    return (
        <div className="home">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
                className="header" style={{ background: `url('../../assets/jakob_foto_header.jpg') right center no-repeat` }}>
                <div className="container">
                    <div className="top">
                        <motion.h1
                            initial={{ opacity: 0, y: -10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                        >
                            Jakob<br /> Augschöll
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0, y: -10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 1.2 }}
                        >
                            Komponist, Arrangeur & Dirigent
                        </motion.p>
                    </div>
                    <div className="bottom">
                        <div className="links">
                            <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                            >
                                <Link to={"/posts/blasorchester"}>
                                    <button>Meine Werke</button>
                                </Link>

                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}
                            >
                                <Link to={"/biografie"}>
                                    <button>Biografie</button>
                                </Link>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.7 }}
                            >
                                <Link to={"/projekte"}>
                                    <button>Aktuelles und Projekte</button>
                                </Link>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <div className="post">
                <div className="container">
                    <motion.h2
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                    >Werke</motion.h2>
                    <Posts />
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}
                    >
                        <Link to={"/posts/blasorchester"}><button>Alle Werke</button></Link>
                    </motion.div>

                </div>
            </div>
        </div>
    )
};

export default Home