import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import moment from "moment";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import "../posts/posts.scss"

const Posts = () => {

    const [posts, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/kompositionen?per_page=8")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="posts">
            {isLoading ? <div className="loading" /> : posts.map((posts, index) => {
                const delay = (index % 5) * 0.1;

                return (
                    <motion.div
                        key={posts.id}
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay }}
                    >
                        <Link className="grid" to={`/post/${posts.slug}`}>
                            <div className="inline">
                                <span className="postTyp">{posts.acf.type}</span>
                                <span className="postKategorie">{posts.acf.category}</span>
                                <h3>{posts.title.rendered}</h3>
                                <span className="postDatum">{moment(posts.acf.year).format("YYYY")}</span>
                                <span className="postBg">{posts.acf.type[0]}</span>
                            </div>
                            <div className="content">
                                <div className="raster">
                                    {posts.acf.audio
                                        ? <span className="element"><PlayArrowOutlinedIcon /></span>
                                        : <span className="element empty"><PlayArrowOutlinedIcon /></span>
                                    }
                                    {posts.acf.pdf
                                        ? <span className="element">PDF</span>
                                        : <span className="element empty">PDF</span>
                                    }
                                    {posts.acf.dur
                                        && <span className="element">{posts.acf.dur}</span>
                                    }
                                    {posts.acf.grad
                                        && <span className="element">{posts.acf.grad}</span>
                                    }
                                </div>
                            </div>
                        </Link>
                    </motion.div>
                );
            })}
        </div>
    )
};

export default Posts