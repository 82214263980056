import React from "react";
import { usePageName } from "react-page-name";
import { motion } from "framer-motion";
import "../notfound/notfound.scss"

const NotFound = () => {

    usePageName(`News | Jakob Augschöll`);

    return (
        <div className="notfound">
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                >
                    404
                </motion.h2>
                <motion.h4
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.25 }}
                >
                    Hallo, nett hier?
                </motion.h4>
                <motion.p
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                >
                    Jedoch ist diese Seite leider nicht mehr verfügbar oder du hast dich ganz einfach nur vertippt.
                </motion.p>
            </div>
        </div>
    )
};

export default NotFound