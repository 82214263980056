import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { motion } from "framer-motion";
import axios from "axios";
import "../biografie/biografie.scss"

const Biografie = () => {

    usePageName(`Biografie | Jakob Augschöll`);

    const [data, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const res = await axios.get("https://api.augschoell.com/wp-json/wp/v2/pages/2/")
                setPosts(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllPosts()
    }, [])

    return (
        <div className="biografie">
            <div className="container">
                {
                    isLoading
                        ? <div className="loading" /> :
                        <div>
                            <motion.h2
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut" }}
                            >
                                {data.title.rendered}
                            </motion.h2>
                            <motion.div
                                className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }}
                                initial={{ opacity: 0, y: 10 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
                            />
                        </div>
                }
            </div>
        </div>
    )
};

export default Biografie