import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { motion } from "framer-motion";
import axios from "axios";
import "../projekte/projekte.scss"

const Projekte = () => {

    const [projekte, setProjekte] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Aussstattung
                const projekteRes = await axios.get(`https://api.augschoell.com/wp-json/wp/v2/projekte?per_page=100`);
                if (projekteRes.data) {
                    setProjekte(projekteRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    })

    usePageName("Aktuelles und Projekte | Jakob Augschöll");

    return (
        <div className="projekte">
            <div className="container">
                <motion.h2
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                >Aktuelles und Projekte
                </motion.h2>
                {isLoading ? <div className="loading" /> : projekte.map((projekte, index) => {
                    const delay = (index % 5) * 0.1;

                    return (
                        <motion.div
                            className="projektContent"
                            key={projekte.id}
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay }}
                        >
                            <div className="top">
                                <h1>{projekte.title.rendered}</h1>
                                <h3>{projekte.acf.date}</h3>
                            </div>
                            <div className="bottom">
                                <div className="wp-content" dangerouslySetInnerHTML={{ __html: projekte.content.rendered }} />
                            </div>
                        </motion.div>
                    );
                })}
            </div>
        </div>
    )
};

export default Projekte